import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_torpedo" }) {
    ...NewsInfo
    innerImages {
      maksimov {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsText extends News {
  renderNews() {
    const { maksimov } = this.props.data.news.innerImages
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Клиент Catenaccio Sports Agency <Link to="/players/maksimov">Максим Максимов</Link> подписал двухлетний контракт с «Торпедо».</p>
          <p>После подписания контракта Максимов заявил клубной пресс-службе: «Очень рад присоединиться к клубу с такой большой историей. Эмоции положительные, спасибо руководству и тренерскому штабу. Сейчас основная задача – набрать оптимальные кондиции и приносить команде пользу, выходить на поле, играть. Я семь лет играл в европейских чемпионатах, считаю, что возвращение в Россию и тем более переход в такой клуб, как «Торпедо» – хороший вариант продолжения карьеры.</p>
          <p>В команде много знакомых лиц, в частности, футболистов – уроженцев Воронежа. Это Рома Шишкин, Руслан Магаль, Дмитрий Цицилин. В тренировочном процессе я уже успел пообщаться и с другими ребятами. Команда очень дружная, хорошо меня приняла, поэтому никаких сложностей нет. Очень жду, когда у меня появится возможность выйти на поле в составе «Торпедо» и проявить себя!»</p>
          
          <Img fluid={ maksimov.childImageSharp.fluid } alt="Максим Максимов" width="100%" />

          <p>Ранее ФИФА запретила Максимову выступать на профессиональном уровне из-за его перехода из «Тракая» в «Вардар» летом 2017 года. Форвард расторг соглашение «Тракай» в соответствии с контрактными условиями за сутки до закрытия трансферного окна и перешел в «Вардар» свободным агентом. В начале августа Максимов при помощи Catenaccio <Link to="/news/maksimov_vs_fifa">выиграл спор у ФИФА</Link>.</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}